import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColumnResizeIcon } from './styled';
import { TABLE_CELL_SCROLLBAR, TABLE_CONTAINER } from './tokens';
import { useVirtualizedTableBoundaries, useVirtualizedTableContext } from './VirtualizedTableComponents';
const flexAlignmentMapper = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
};
const ColumnContainer = styled.div `
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;
const ColumnChildrenContainer = styled.div `
  width: 100%;
`;
const ColumnHeaderContainer = styled.div `
  display: flex;
  justify-content: ${(props) => props.align && flexAlignmentMapper[props.align]};
`;
export function useResizableColumnHeaders(tableSelector, tableHeaderRef, columns, columnWidthMap) {
    const [isResizing, setIsResizing] = useState(false);
    const initialColumnWidthsRef = useRef();
    const getAllCols = usePersistentCallback(function getAllCols(tableContainer, noOfCols) {
        const colgroups = tableContainer.querySelectorAll('colgroup');
        let colsGrid = [];
        for (let i = 0; i < colgroups.length; i++) {
            const cols = colgroups.item(i).querySelectorAll('col');
            const missingColCount = noOfCols - cols.length;
            colsGrid[i] = Array.from(cols);
            for (let j = 0; j < missingColCount; j++) {
                const newCol = document.createElement('col');
                colsGrid[i].push(newCol);
                colgroups[i].append(newCol);
            }
        }
        return colsGrid;
    });
    function getAllHeaders(tableContainer) {
        const rows = tableContainer.querySelectorAll('tr');
        let headers = [];
        for (const row of rows) {
            const ths = row.querySelectorAll(`th:not(.${TABLE_CELL_SCROLLBAR})`);
            headers.push(...Array.from(ths));
        }
        return headers;
    }
    function getColumnWidths(headers) {
        const columnWidths = [];
        for (const header of headers) {
            columnWidths.push(header.getBoundingClientRect().width);
        }
        return columnWidths;
    }
    const getInitialColumnWidths = usePersistentCallback(function getInitialColumnWidths(headers) {
        if (!initialColumnWidthsRef.current) {
            initialColumnWidthsRef.current = getColumnWidths(headers);
        }
        return initialColumnWidthsRef.current;
    });
    const setWidthForColSets = usePersistentCallback(function setWidthForColSets(colSets, index, width) {
        for (const colSet of colSets) {
            colSet[index].style.width = `${width}px`;
        }
    });
    const onMouseDown = usePersistentCallback((evt, columnIndex) => {
        const startX = evt.pageX;
        const tableContainer = tableHeaderRef.current?.closest(tableSelector);
        setIsResizing(true);
        const oldCursor = document.body.style.cursor;
        if (!tableContainer)
            return;
        const tableHeaders = getAllHeaders(tableContainer);
        const initialColumnWidths = getInitialColumnWidths(tableHeaders);
        const currentColumnWidths = getColumnWidths(tableHeaders);
        const noOfColumns = currentColumnWidths.length;
        const colSets = getAllCols(tableContainer, noOfColumns);
        // set initial width to all colgroups
        currentColumnWidths.forEach((width, index) => setWidthForColSets(colSets, index, width));
        const mouseMoveListener = (evt) => {
            // disable text selection during table resizing
            document.body.style.userSelect = 'none';
            document.body.style.cursor = 'col-resize';
            const nextColumnIndex = columnIndex + 1;
            const isResizingRight = evt.pageX > startX;
            const MIN_WIDTH = columns[columnIndex]?.minWidth || 100;
            const dx = evt.pageX - startX;
            const minWidth = Math.min(MIN_WIDTH, initialColumnWidths[columnIndex]);
            let width = currentColumnWidths[columnIndex] + dx;
            // if the current column has reached min width then don't allow resizing
            if (width <= minWidth)
                return;
            // we increase/decrease the current column width by widthDx
            // and we do the inverse of widthDx on the next column width
            const widthDx = width - currentColumnWidths[columnIndex];
            let nextColumnWidth = currentColumnWidths[nextColumnIndex] - widthDx;
            // if the next column has reached minimum width then don't allow reizing
            if (nextColumnIndex < noOfColumns && isResizingRight) {
                const MIN_WIDTH = columns[nextColumnIndex].minWidth || 100;
                const nextColumnMinWidth = Math.min(MIN_WIDTH, initialColumnWidths[nextColumnIndex]);
                if (nextColumnWidth <= nextColumnMinWidth)
                    return;
            }
            setWidthForColSets(colSets, columnIndex, width);
            setWidthForColSets(colSets, nextColumnIndex, nextColumnWidth);
            // update column width cache when virtualization is involved
            if (columns[columnIndex].key) {
                columnWidthMap.set(columns[columnIndex].key.toString(), width);
            }
            if (columns[nextColumnIndex].key) {
                columnWidthMap.set(columns[nextColumnIndex].key.toString(), nextColumnWidth);
            }
        };
        document.addEventListener('mousemove', mouseMoveListener);
        document.addEventListener('mouseup', () => {
            document.body.style.userSelect = 'auto';
            document.body.style.cursor = oldCursor;
            document.removeEventListener('mousemove', mouseMoveListener);
            setIsResizing(false);
        }, { once: true });
    });
    const getEventHandlers = useCallback((columnIndex) => {
        return { onMouseDown: (evt) => onMouseDown(evt, columnIndex) };
    }, [onMouseDown]);
    return { getEventHandlers, isResizing };
}
const tableContainerSelector = `.${TABLE_CONTAINER}`;
const DefaultCellComponent = (props) => _jsx(_Fragment, { children: props.children });
export const ResizableTableHeader = memo(function ResizableTableHeader({ columns, CellComponent = DefaultCellComponent, virtualizeColumns, ...props }) {
    const columnWidthMap = useVirtualizedTableContext((context) => context.columnWidthMap);
    const columnStartIndex = useVirtualizedTableBoundaries((context) => context.columnStartIndex);
    const columnEndIndex = useVirtualizedTableBoundaries((context) => context.columnEndIndex);
    const { columnIndex, align } = props;
    const thRef = useRef(null);
    const [isHover, setIsHover] = useState(false);
    const isLastColumn = columnIndex === columns.length - 1;
    const { getEventHandlers, isResizing } = useResizableColumnHeaders(tableContainerSelector, thRef, columns, columnWidthMap);
    const columnResizingClass = 'ui-column-resizing';
    function isSiblingsBeingResized() {
        const tableContainer = document.querySelector(tableContainerSelector);
        if (tableContainer) {
            return (!thRef.current?.classList.contains(columnResizingClass) &&
                tableContainer.querySelector(`.${columnResizingClass}`));
        }
        else {
            return false;
        }
    }
    useEffect(() => {
        if (isResizing) {
            thRef.current?.classList.add(columnResizingClass);
        }
        else {
            thRef.current?.classList.remove(columnResizingClass);
        }
    }, [isResizing]);
    const isResizeVisible = Boolean(!isLastColumn &&
        props.resizable &&
        // avoid showing resize icons for other columns when a particular column is being resized
        ((isHover && !isSiblingsBeingResized()) || isResizing));
    const hasFilter = Boolean(columns[columnIndex]?.filter);
    const isLeftAligned = !align || align === 'left';
    const inViewPort = virtualizeColumns ? columnIndex >= columnStartIndex && columnIndex <= columnEndIndex : true;
    return (_jsx("th", { ...props, width: 'auto', 
        // @ts-ignore
        // thRef is epected to be LegacyRef rather than MutableRefObject
        // seems to be a bug in react typings
        ref: thRef, onMouseLeave: () => {
            setIsHover(false);
        }, onMouseEnter: () => {
            setIsHover(true);
        }, children: inViewPort && (_jsx(ColumnHeaderContainer, { align: align, children: _jsxs(ColumnContainer, { fullWidth: hasFilter || isLeftAligned, children: [_jsx(ColumnChildrenContainer, { children: _jsx(CellComponent, { ...props }) }), _jsx(ColumnResizeIcon, { isVisible: isResizeVisible, ...getEventHandlers(columnIndex) })] }) })) }));
});
export function addResizableColumnHeaderProps(columns) {
    columns.forEach((column, index) => {
        // if a column is not resizable then make the previous column also non resizable
        // this avoids resizing the non-resizable column by side effect of near by column
        if (column.resizable === false && index > 0) {
            columns[index - 1].resizable = false;
        }
    });
    return columns.map((column, index) => {
        column.onHeaderCell = (column) => {
            return {
                width: column.width,
                columnIndex: index,
                align: column.align,
                resizable: typeof column.resizable === 'undefined' ? true : column.resizable,
                minWidth: column.minWidth,
                isLastColumn: index === columns.length - 1
            };
        };
        return column;
    });
}
