import { ArrayElement, ExtractArrayType, ValueOf } from '@prophecy/interfaces/generic';
import { SupportedLanguages } from '@prophecy/ui';
import { EdgeData, NodeData } from '@prophecy/ui/Graph/types';
import { SqlProjectContentQueryResponse } from 'frontend/core/src/common/queries/sqlProjectRelations';
import { Edge, Node } from 'reactflow';

import { BackEndCodeLanguageType, ForkModeType, SyncModeType } from '../../graphqlTypes/enums';
import { LSP } from '../../LSP/base/types';
import type { Diagnostic, FileDefinitions, TextDocument as LSPTextDocument } from '../../LSP/types';
import type { GemSpec, GemType } from '../../Parser/types';
import type { DependencyStatusAndDisplayMessageType, DependencyType, Port } from '../../redux/types';
import { ProviderType } from '../../schema/types';
import type { CatalogFunction } from '../catalog/CatalogProvider';
import type { CopilotAgentState, CopilotState } from '../Copilot/types';
import type {
  ExpectationLayoutType,
  ExpectationMetadata,
  ExpectationType,
  LoadingState,
  Threshold
} from '../DataQuality/types';
import { v1DataExplorerType } from '../interims/conditionTypes';
import { Entity } from './Entity';

export type DiagnosticsMap = { [key: string]: Diagnostic[] };
export type GraphElements = (Node<NodeData> | Edge<EdgeData>)[];

interface ExternalSyncStatus {
  state: string;
  displayState: string;
  displayMessage: string;
}

export interface Project {
  id: string;
  name: string;
  forkMode: ForkModeType;
  syncMode: SyncModeType;
  externalSyncStatus: ExternalSyncStatus;
  externalOriginUri?: string;
  mainBranch: string;
  providerType?: ProviderType;
  language: BackEndCodeLanguageType;
  teamId: string;
  workingBranch: {
    name: string;
  };
}

export interface ExampleProject {
  _id: string;
  name: string;
  sqlOrchestratorPipelines: ExtractArrayType<
    NonNullable<SqlProjectContentQueryResponse['Project']>['sqlOrchestratorPipelines']
  >[];
}

export type Connection = {
  id: string;
  source: string;
  sourcePort: string;
  target: string;
  targetPort: string;
  visualProperty?: { isHidden: boolean };
};

export interface BaseProcessMetadata {
  label: string;
  comment?: string;
  autoUpdateComment?: boolean;
  passThrough?: string;
  removalCondition?: string;
  isLabelGrayed?: boolean;
  slug: string;
  x: number;
  y: number;
  width?: number;
  height?: number;
  isAutoLayoutEnabled?: boolean;
  dataExplorerProps?: v1DataExplorerType;
  visualProperty?: { isHidden: boolean };
}

export enum GitProviderType {
  prophecy = 'ProphecyManaged',
  external = 'External'
}

export type ParamConfig = {
  configName: string;
  propertyPath: string;
};

export type DiffProperties = {
  configured: boolean;
  keyColumns: string[];
  sourceType: 'Parquet' | 'CatalogTable';
  parquet?: { location: string };
  catalogTable?: { catalog: string; schema: string; table: string; isCatalogEnabled?: boolean };
};

export interface BaseProcess<PM extends BaseProcessMetadata, Prop = unknown> extends GemType {
  id: string;
  properties: Prop;
  paramProperties?: ParamConfig[];
  metadata: PM;
  parent?: string;
  ports?: {
    isCustomOutputSchema?: boolean;
    autoUpdateOnRun?: boolean;
    inputs?: Port[];
    outputs?: Port[];
  };
  diff?: DiffProperties;
}

export interface BaseMetaInfo {
  frontEndLanguage: BackEndCodeLanguageType;
  language: BackEndCodeLanguageType;
}

export type GenericProcess<PM extends BaseProcessMetadata, P extends BaseProcess<PM>> = P;

export type GenericGraphProcess<GM, PM extends BaseProcessMetadata, P extends BaseProcess<PM>, C extends Connection> =
  | GenericProcess<PM, P>
  | GenericSubGraph<GM, PM, P, C>;
export type GenericGraphProcesses<
  GM,
  PM extends BaseProcessMetadata,
  P extends BaseProcess<PM>,
  C extends Connection
> = {
  [key: string]: GenericGraphProcess<GM, PM, P, C>;
};
export type GenericSubGraph<
  GM extends unknown,
  PM extends BaseProcessMetadata,
  P extends BaseProcess<PM>,
  C extends Connection
> = GenericProcess<PM, P> & {
  processes: GenericGraphProcesses<GM, PM, P, C>;
  connections: C[];
  metainfo: GM;
};

export type GenericGraph<
  GM extends unknown,
  PM extends BaseProcessMetadata,
  P extends BaseProcess<PM>,
  C extends Connection
> = GenericSubGraph<GM, PM, P, C>;

export type GenericGraphProcessType<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
> = ValueOf<G['processes']>;
export type Process = GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;
export type Job = {
  label: string;
  value: string;
  enabled: boolean;
  schedule: string;
  scheduleHelp: string;
  targetEntity?: Entity;
  targetEntityName?: string;
};
export type Metadata = {
  projectId?: string;
  projectName?: string;
  configTopLevelPackage?: string;
  project?: Project;
  jobs?: Job[];
  isDatasetInfo?: boolean;
  schedulers?: { label: string; value: { fabricName: string; scheduler: string; fabricId: string } }[];
  workflowMeta?: {
    workflowUID: string;
    workflowId: string;
    workflowName: string;
  };
  time_zones?: Array<{ label: string; value: string }>;
};
export enum EditMode {
  disabled = 'disabled',
  enabled = 'enabled',
  configuration = 'configuration'
}

export interface BaseState<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  M = Metadata
> {
  branch?: string;
  editMode: EditMode;
  copilot?: CopilotState;
  copilotAgent?: CopilotAgentState;
  historical?: boolean;
  banner: string;
  root: string;
  gemSpecs: GemSpec[];
  $graph?: G;
  logMessage: string;
  loader: boolean;
  currentGraph?: G;
  graphPath: string;
  currentComponentId?: string;
  skipHistory?: boolean;
  metadata: M;
  expectation?: ExpectationMetadata;
}

export type CommonGraph = GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;

export type CommonState = BaseState<CommonGraph>;

export type TextDocument = LSPTextDocument & {
  language: SupportedLanguages;
  definitions?: FileDefinitions['definitions'];
  diagnostics?: Diagnostic[];
};
export type TextDocuments = TextDocument[];
export type Change = { type: 'partial' | 'full'; value: unknown; property: string };
export type WindowMessage = { elementType: 'toast'; message: string; variant: 'error' | 'warning' | 'info' };

type UpdateChange = Change & { oldValue?: unknown };
export type DidUpdatePayload = UpdateChange[];

export type DidChangeEntry = Omit<Change, 'type'> & { handleHistoryDidUpdate?: boolean; requestId?: string };
export type DidChangePayload = DidChangeEntry | DidChangeEntry[];

export type DidActionPayload = {
  graphPath?: string;
  elementId: string;
  actionName: string;
  params?: unknown;
};
export enum CommonActionTypes {
  logMessage = 'window/logMessage',
  dialogOpen = '@dialog.open',
  dialogClose = '@dialog.close',
  currentGraphPath = '@canvas.graphPath',
  loaderOn = 'loaderOn',
  loaderOff = 'loaderOff',
  componentDidOpen = 'component/DidOpen',
  propertyDidUpdate = 'properties/DidUpdate',
  propertiesDidMasterUpdate = 'properties/DidMasterUpdate',
  propertiesDidSchemaUpdate = 'properties/DidSchemaUpdate',
  windowShowMessage = 'window/showMessage',
  initialize = 'initialize',
  $updateState = '$updateState',

  //Expectation actions
  definitionLoader = 'definitionLoader',
  setExpectationDefinition = 'setExpectationDefinition',
  setExpectationUISpec = 'setExpectationUISpec',
  setExpectationDefaultInterval = 'setExpectationDefaultInterval',

  toggleVisualCodeButton = 'toggleVisualCodeButton',

  // copilot actions
  updateCopilotState = 'updateCopilotState',

  // dependencies
  dependenciesDidResolve = 'dependenciesDidResolve',
  featuresDidOpen = 'featuresDidOpen'
}

export type COMMON_ACTION_TYPES<
  T extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  M = Metadata,
  S = BaseState<T, M>
> =
  | { type: CommonActionTypes.logMessage; payload: string }
  | {
      type: CommonActionTypes.dialogOpen;
      payload: { componentId: string };
    }
  | { type: CommonActionTypes.currentGraphPath; payload: string }
  | { type: CommonActionTypes.dialogClose }
  | { type: CommonActionTypes.loaderOff }
  | { type: CommonActionTypes.toggleVisualCodeButton; payload: boolean }
  | { type: CommonActionTypes.loaderOn }
  | { type: CommonActionTypes.componentDidOpen; payload: GemSpec[] }
  | {
      type: LSP.Method.propertiesDidChange;
      payload: DidChangePayload;
    }
  | { type: LSP.Method.propertiesDidSave; payload?: { isAwaitedSave?: boolean } }
  | {
      type: CommonActionTypes.propertyDidUpdate;
      savePending?: boolean;
      payload: DidUpdatePayload;
    }
  | {
      type: CommonActionTypes.propertiesDidMasterUpdate;
      payload: DidUpdatePayload;
    }
  | { type: CommonActionTypes.propertiesDidSchemaUpdate; payload: DidUpdatePayload }
  | { type: LSP.Method.propertiesDidReset }
  | { type: LSP.Method.propertiesDidAction; payload: DidActionPayload }
  | { type: LSP.Method.metadataDidReload }
  | { type: CommonActionTypes.$updateState; payload: Partial<S> }
  | {
      type: CommonActionTypes.initialize;
      payload: { editMode: EditMode; banner: string; historical: boolean; sessionId: string };
    }
  | {
      type: CommonActionTypes.setExpectationDefinition;
      payload: { thresholds: Threshold[]; expectationTypes: ExpectationType[] };
    }
  | {
      type: CommonActionTypes.definitionLoader;
      payload: { loading: boolean; key?: keyof LoadingState };
    }
  | { type: CommonActionTypes.setExpectationUISpec; payload: ExpectationLayoutType }
  | { type: CommonActionTypes.setExpectationDefaultInterval; payload: string }
  | { type: CommonActionTypes.updateCopilotState; payload: Partial<CopilotState>; replace?: boolean }
  | { type: CommonActionTypes.dependenciesDidResolve; payload: DependencyType[] }
  | { type: CommonActionTypes.featuresDidOpen; payload: FeatureDidOpen }
  | { type: CommonActionTypes.windowShowMessage; payload: WindowMessage };

type CatalogTable = {
  name: string;
};
export type CatalogDatabases = {
  [key: string]: {
    isFunctionLoading: boolean;
    functions?: CatalogFunction[];
    isTableLoading: boolean;
    tables?: CatalogTable[];
  };
};
export type Catalogs = {
  [key: string]: {
    isDatabaseLoading: boolean;
    databases: CatalogDatabases;
  };
};

export enum SpecLanguage {
  python = 'python',
  scala = 'scala'
}

export enum AirflowSpecLanguage {
  python = 'python'
}

export type Session = {
  id: string;
  poolName: string;
  name: string;
  label: string;
  workers: string;
  numWorkers: number;
  dependencyStatusAndDisplayMessage: DependencyStatusAndDisplayMessageType;
  sessionState: boolean;
  terminated: boolean;
  state: string;
  isAllowed?: boolean;
  accessibility: {
    shortMessage?: string;
    longMessage?: string;
    isAccessible: boolean;
  };
  tabId: string;
};
export type ClusterGroup = {
  heading: string;
  clusters: Session[];
};

export type SessionResponse = {
  ephemeralClusters: ClusterGroup;
  longLivingClusters: ClusterGroup;
};

export type SubgraphConnectionMap = Record<
  string,
  {
    source: BaseProcess<BaseProcessMetadata>;
    sourcePort: string;
    targetPort: string;
  }
>;

export type GraphMetadata<
  T = GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>
> = {
  graph: T;
  graphPath: string;
  rootGraph: T;
  rootPath: string;
  readOnly?: boolean;
};

export type ExecutionError = {
  msg?: string;
  trace?: string | string[];
  data?: string;
};
export enum ProjectSourceType {
  imported = 'imported',
  new = 'new'
}
export enum PrivilegeType {
  Subscriber = 'Subscriber',
  Author = 'Author'
}
export enum DatasetDescriptionStatus {
  fromCopilotWithInterim = 'fromCopilotWithInterim',
  fromCopilot = 'fromCopilot',
  fromUser = 'fromUser'
}
export type AdditionalPropertiesType = {
  copilot: {
    datasetDescriptionStatus: `${DatasetDescriptionStatus}`;
  };
};
export type BaseSourceSuggestion = {
  sourceLabel: string;
  canJoinWith: {
    processLabel: string;
    processId: string;
    properties: {
      columnsSelector: string[];
      conditions: {
        alias: string;
        expression: string;
        joinType: string;
      }[];
      expressions: {
        alias: string;
        expression: string;
      }[];
      headAlias: string;
    };
    isRecommended: boolean;
    joinColumns: string[];
  }[];
};
export type SparkSourceSuggestion = {
  sourceDatasetId: string;
} & BaseSourceSuggestion;
export type SourceSuggestionsReferenceProcess = ArrayElement<BaseSourceSuggestion['canJoinWith']>;

export type FeatureDidOpen = {
  [feature: string]: {
    [subfeature: string]: {
      enabled: boolean;
      reason?: string;
      resolution?: string;
    };
  };
};
